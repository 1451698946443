<template>
  <DefaultLayout>
    <section class="bg-white">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h1
            class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600"
          >
            {{ error?.statusCode }}
          </h1>
          <p class="mb-4 text-lg font-light text-gray-500">
            {{ t(`error.${statusMessage}`) }}
          </p>
        </div>
      </div>
    </section>
  </DefaultLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";
import { useI18n } from "vue-i18n";
import DefaultLayout from "~/layouts/default.vue";

const { t } = useI18n();

const statusMessage = computed(() => {
  if (props.error?.statusMessage === "user_not_found") return "user_not_found";
  if (props.error?.statusMessage === "challenge_not_found")
    return "challenge_not_found";
  return "page_not_found";
});

const props = defineProps({
  error: Object as () => NuxtError,
});

useHead({
  title: "Not found | SendChallenges",
});

definePageMeta({
  layout: "default",
});

onBeforeRouteLeave(() => {
  clearError();
});
</script>
