<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
useHead({
  title: "SendChallenges | Send Challenges to your friends or enemies",
  meta: [
    {
      name: "description",
      content: "Create a challenge and let's see who is the best!",
    },
  ],
});
</script>
