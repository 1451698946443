<template>
  <label :class="labelClasses">
    <input
      v-model="model"
      :disabled="disabled"
      class="sr-only peer"
      type="checkbox"
    />
    <span :class="[toggleClasses, toggleSize, toggleColor]" />
    <span :class="toggleBallClasses">{{ label }}</span>
  </label>
</template>

<script lang="ts" setup>
import { computed, toRefs } from "vue";
import { useToggleClasses } from "./composables/useToggleClasses";
import type { InputSize } from "~/components/Flowbite/FwbInput/types";

interface ToggleProps {
  color?: string;
  disabled?: boolean;
  label?: string;
  modelValue?: boolean;
  size?: InputSize;
}

const props = withDefaults(defineProps<ToggleProps>(), {
  color: "",
  disabled: false,
  label: "",
  modelValue: false,
  size: "md",
});

const emit = defineEmits(["update:modelValue"]);
const model = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

const {
  labelClasses,
  toggleSize,
  toggleClasses,
  toggleColor,
  toggleBallClasses,
} = useToggleClasses(toRefs(props));
</script>
~/components/Flowbite/FwbInput/types
